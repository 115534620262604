/*

Tooplate 2117 Infinite Loop

https://www.tooplate.com/view/2117-infinite-loop

*/
@import url("bootstrap.min.css");
@import url("responsives.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900");


body {
	font-family: "Raleway", sans-serif;
	font-size: 1.2em;
  color: #707070;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

a {
  transition: all 0.3s ease;
  color: #38B;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #D40;
}

a:focus {
  outline: none;
}

.btn {
  padding: 8px 32px;
}

.btn:hover {
  background-color: #ced4da;
}

blockquote {
  font-size: 0.86em;
  line-height: 1.8em;
}

.tm-section-pad-top {
  padding-top: 80px;
  padding-bottom: 40px;
}

.tm-content-box {
  padding-top: 20px;
  padding-bottom: 40px;
}

.tm-text-primary {
  color: #37A;
}

.tm-font-big {
  font-size: 1.25rem;
}

.tm-btn-primary {
  color: white;
  background-color: #369;
  padding: 14px 30px;
}

.tm-btn-primary:hover,
.tm-btn-primary:focus {
  color: white;
  background-color: #38B;
}

/* Navbar */

.tm-navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;
}

.tm-navbar.scroll {
  background-color: white !important;
  border-bottom: 1px solid #e9ecef;
}

.navbar-brand {
  color: white !important;
  font-size: 1.4rem;
  font-weight: bold;
}

.navbar-brand:hover,
.tm-navbar.scroll .navbar-brand:hover {
  color: #38B;
}

.tm-navbar.scroll .navbar-brand {
  color: #369;
}

.nav-item {
  list-style: none;
}

.tm-nav-link {
  color: white;
}

.tm-navbar.scroll .tm-nav-link {
  color: #369;
}

.tm-navbar.scroll .tm-nav-link:hover,
.tm-navbar.scroll .tm-nav-link.current,
.tm-nav-link:hover {
  color: #FFF;
  background-color: #369;
}

.navbar-toggler {
  border: 1px solid white;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-toggler-icon {
  color: white;
  padding-top: 6px;
}

.tm-navbar.scroll .navbar-toggler {
  border: 1px solid #707070;
}

.tm-navbar.scroll .navbar-toggler-icon {
  color: #707070;
}

/* Hero */

#infinite, #infinite-insolvency {
	background-color: #222;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 375px;
  position: relative;
}

#infinite {
  background-image: url(../img/infinite-loop-01.jpg);
}

#infinite-insolvency {
  background-image: url(../img/clouds.jpg);
}

@media (min-height: 600px) and (min-width: 1920px) {
  #infinite, #infinite-insolvency {
    background-size: cover;
  }
}

@media (min-height: 830px) {
  #infinite, #infinite-insolvency {
    background-position: center -210px;
  }
}

@media (min-height: 680px) and (max-height: 829px) {
  #infinite, #infinite-insolvency {
    background-position: center -300px;
  }
}

@media (min-height: 500px) and (max-height: 679px) {
  #infinite, #infinite-insolvency {
    background-position: center -400px;
  }
}

@media (max-height: 499px) {
  #infinite, #infinite-insolvency {
    background-position: center -450px;
  }
}

.tm-hero-text-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.tm-hero-text-container-inner {
  margin-top: -90px;
}

.tm-hero-title {
  font-size: 3.5rem;
  text-shadow: 2px 2px 2px #333;
}

.tm-hero-subtitle {
  text-shadow: 2px 2px 2px #333;
}

.tm-intro-next {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

@media (max-height: 480px) {
  .tm-hero-text-container-inner {
    margin-top: -40px;
  }

  .tm-intro-next {
    bottom: 20px;
  }
}

.tm-down-arrow-link {
  display: block;
  margin-top: 18%;
}

.tm-down-arrow {
  	color: #FFF;
    cursor: pointer;
    background: #357;
    padding: 15px 40px;
    transition: all 0.3s ease;
}

.tm-down-arrow:hover,
.tm-down-arrow:focus {
  color: #FFF;
  background: #37A;
  padding: 20px 50px;
}

/* Introduction */

#introduction {
  padding-bottom: 100px;
}

.tm-section-title {
  font-size: 2.6rem;
  font-weight: normal;
}

.tm-section-title-alt {
  font-size: 2rem;
  font-weight: normal;
}

.tm-intro-text {
  font-size: 1.2rem;
}

.tm-icon {
  display: block;
  color: #37A;
}

.tm-continue {
	padding: 20px 0 30px 0;
}

/* how-works */
#how-works, #how-works-insolvency {
  color: white;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

/* how-works */
#how-works {
  background-image: url(../img/infinite-loop-02.jpg);
}

#how-works-insolvency {
  background-image: url(../img/bridge.jpg);
}

@media (max-width: 991px) {
  #how-works {
    background-image: url(../img/infinite-loop-02-mobile.jpg);
  }
}

.tm-how-works-content {
  position: relative;
  z-index: 100;
}

.tm-bg-overlay {
  width: 100%;
  height: 100%;
  background: rgba(20, 70, 80, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.tm-how-works-carousel {
  max-width: 1050px;
  margin: 0 auto;
}

.tm-testimonial-item {
  max-width: 290px;
  margin-left: 35px;
  margin-right: 35px;
}

.tm-testimonial-item img {
  border-radius: 50%;
  margin-bottom: 35px;
}

.tm-testimonial-item figcaption {
  text-align: right;
  font-style: italic;
  font-size: 1.1rem;
}

/* Work */

.tm-section-desc {
  max-width: 650px;
  width: 100%;
  font-size: 0.9rem;
}

.tm-gallery-container {
  padding-top: 40px;
  padding-bottom: 20px;
}

.tm-gallery-item {
  margin: 0 15px;
}

.slick-dots {
  bottom: -65px;
}

.slick-dots li {
  margin: 0 13px;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before,
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #3ba0dd;
}

.tm-how-works-carousel .slick-dots li button:before {
  color: white;
  opacity: 0.5;
}

.tm-how-works-carousel .slick-dots li button:hover:before,
.tm-how-works-carousel .slick-dots li button:focus:before,
.tm-how-works-carousel .slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 18px;
}

/* Hover Effect */
/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-size: 0.9em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 600;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Honey *****/
/*---------------*/

figure.effect-honey {
  background: #4a3753;
  max-width: 220px;
}

figure.effect-honey img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-honey:hover img {
  opacity: 0.4;
}

figure.effect-honey figcaption::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #38C;
  content: "";
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

figure.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey figcaption::before,
figure.effect-honey h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tm-container-gallery {
	padding-top: 30px;
	}

/* Contact */

#contact {
  color: #d0d0d0;
  background: rgb(28, 37, 70);
  background: linear-gradient(218deg, #335577 0%, rgb(0, 0, 0) 100%);
  /* background-image: url(../img/infinite-loop-03.jpg); */
  background-position: center 0px !important;
  background-repeat: no-repeat;
  min-height: 980px;
  position: relative;
  padding-bottom: 50px;
  padding-top: 100px;
}

.contact-item {
  margin-left: 20px;
  margin-bottom: 50px;
}

.item-link {
  display: flex;
  align-items: center;
}

.item-link i,
.item-link span {
  color: #d0d0d0;
  transition: all 0.3s ease;
}

.item-link:hover i,
.item-link:hover span {
  color: #3496d8;
}

.tm-input {
  margin: 0 0 20px 0;
  width: 100%;
  padding: 15px 20px;
  border-radius: 20px;
  border: 1px solid rgb(162, 162, 162);
  background: #7e7e7e32;
  color: white;
  -webkit-box-shadow: 4px 3px 5px -3px rgb(0, 0, 0);
  -moz-box-shadow: 4px 3px 5px -3px rgb(0, 0, 0);
  box-shadow: 4px 3px 5px -3px rgb(0, 0, 0);
}

.tm-btn-submit {
	font-size: 0.9em !important;
	color: #fff !important;
	background-color: transparent !important;
  border: 1px solid #fff !important;
	width: 50% !important;
	margin-bottom: 60px !important;
}

.tm-btn-submit:hover {
	color: #fff;
	background-color: #38B;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.tm-footer {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  padding: 0 15px;
}

.tm-footer a {
	color: #fff;
}

.tm-footer a:hover {
	color: #9CF;
}

.tm-footer-link {
  color: white;
}

.tm-footer-link:hover,
.tm-footer-link:focus {
  color: #38B;
  text-decoration: none;
}

p {
  line-height: 1.9;
}

@media (min-width: 768px) {
  .tm-intro-text-container {
    padding-left: 0px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1275px;
  }

  .tm-container-gallery {
    max-width: 1290px;
  }

  .tm-container-contact {
    max-width: 1063px;
  }
}

@media (max-width: 991px) {
  .tm-intro-text-container {
    padding-left: 15px;
    padding-top: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-intro-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-btn-submit {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    /* max-width: 200px; */
    text-align: right;
  }

  .navbar-collapse {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 3px;
  }

  .navbar-collapse .nav-link {
    color: #707070;
	padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .tm-gallery-container {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-gallery-container-2 {
    max-width: 350px;
  }

  .slick-dots li {
    margin: 0 8px;
  }

  .tm-gallery-item {
    margin: 0;
  }
}


/* Houses Card */ 

.custom-card, .custom-card > a {
  border-radius: 73px 0px 43px 0px;
  -moz-border-radius: 50px 0px 43px 0px;
  -webkit-border-radius: 50px 0px 43px 0px;
  border: 1px solid #bebebe;
  box-shadow: 4px 3px 5px -3px rgba(128, 128, 128, 1);
  min-width: 250px;
  color: #38B;
}


.custom-card > a:hover {
 text-decoration: none;
}



.class {
  color: #fff;
  background-color: #276285;
  font-size: 19px;
  font-weight: 700;
  max-width: 140px;
  text-align: center;
  margin-left: auto;
}

.class-selled {
  color: #fff;
  background-color: #c70a0a;
  font-size: 19px;
  font-weight: 700;
  max-width: 140px;
  text-align: center;
  margin-left: auto;
}

.custom-img {
  background-color: #ededed;
  width: 100%;
  height: 250px;
  border-radius: 50px 0px 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-card-body {
  padding: 20px 20px;
}

.custom-info {
  display: flex;
  margin: 20px 0px;
}

.custom-info div {
  display: flex;
}

.separator {
  width: 1px;
  height: auto;
  background-color: #8c8c8c;
  margin: 0px 10px;
}

.final-price {
  font-size: 24px;
}

.custom-btn {
  background-color: #276285;
  color: white;
  text-align: center;
  max-width: 220px;
  margin: 30px auto 0px auto;
}

#gallery > div:nth-child(2) > a, #how-works > div:nth-child(2) > a, #how-works-insolvency > div:nth-child(2) > a {
  margin-top: 50px;
}

.blue-item{
  color: #38B;
}

.card-item {
  margin-top: 10px;
}

.sec-item-price {
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 1px solid #bebebe;
  -webkit-box-shadow: 4px 3px 5px -3px rgba(128,128,128,1);
  -moz-box-shadow: 4px 3px 5px -3px rgba(128,128,128,1);
  box-shadow: 4px 3px 5px -3px rgba(128,128,128,1);
  padding: 20px;background-color: #f9f9f9;
}


#contact svg {
  transition: all 0.3s ease;
  color: #fff;
  text-decoration: none;
}


.main-image .img{
  width: 100%;
  min-height: 410px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-image, .aux-img {
  margin: 5px 5px;
  min-width: 250px;
}

.aux-img .img {
  width: 100%;
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/* step by step */

#how-works .body-card, #how-works-insolvency .body-card {
  padding: 10px 10px;
}

#how-works .body-card > div:first-child, #how-works-insolvency .body-card > div:first-child {
  background-color: #06121a55;
  padding: 50px 20px;
  border-radius: 5%;
  z-index: 10000;
  transition: all 0.5s ease;
}

#how-works .body-card > div:first-child:hover, #how-works-insolvency .body-card > div:first-child:hover {
  background-color: #aeaeae55;
  transition: all 0.3s ease;
}

#how-works svg, #how-works-insolvency svg {
  font-size: 85px;
}

#how-works .sbt-title, #how-works-insolvency .sbt-title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 30px 0px;
}

#how-works .sbt-text, #how-works-insolvency .sbt-text {
  font-size: 15px;
  padding: 0px 20px;
}

/* RESPONSIVES */ 

.father-box {
  display: flex;
  flex-wrap: wrap;
}

.child-box {
  flex: 1 1;
}

.child-sm-box {
  flex: 1 1;
}


/* RESPONSIVE TABLE */ 

table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  /* width: 100%; */
  /* table-layout: fixed; */
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: left;
  border: 1px solid #ebebeb;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}


.table>:not(caption) {
  border-bottom-width: 0px !important;

}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 1px solid rgb(240, 240, 240);
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid rgb(235, 235, 235);
    display: block;
    font-size: 22px;
    text-align: right;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }

  .table>:not(caption) {
      border-bottom-width: 10px !important;
  
  }
}

/* Logo */

.logo img {
  filter: saturate(1);
  padding: 10px;
  min-width: 200px;
  max-width: 200px;
  background-color: #d7d7d761;
  border-radius: 10%;
  transition: all 0.6s ease;
}

.logo:hover img {
  filter: saturate(0.5);
  padding: 10px;
  min-width: 200px;
  max-width: 200px;
  background-color: #d7d7d79f;
  border-radius: 10%;
  transition: all 0.6s ease;
}

#infinite-insolvency .logo img {
  filter: saturate(1);
  padding: 10px;
  min-width: 200px;
  max-width: 250px;
  background-color: #4e4e4e1b;
  border-radius: 10%;
  transition: all 0.6s ease;
}

#infinite-insolvency .logo:hover img {
  filter: saturate(1.8);
  padding: 10px;
  min-width: 200px;
  max-width: 250px;
  background-color: #3939391e;
  border-radius: 10%;
  transition: all 0.6s ease;
}