.father-box {
    display: flex;
    flex-wrap: wrap;
}

.child-box {
    flex: 1 1;
    min-width: 450px;
    margin: 5px;
    padding: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
}

.child-sm-box {
    flex: 1 1;
    min-width: 350px;
    margin: 5px;
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
}

/* RESPONSIVE TABLE */ 

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    padding: .35em;
  }
  
  table th,
  table td {
    padding: .625em;
    text-align: left;
    border: 1px solid #403f3f;

    text-overflow: ellipsis; /* Corta el texto con "..." */
    white-space: nowrap;    /* Evita que el texto haga salto de línea */
    overflow: hidden;       /* Esconde el texto que se sale */
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }


  .table>:not(caption) {
    border-bottom-width: 0px !important;

}
  
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 1px solid rgb(240, 240, 240);
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid rgb(235, 235, 235);
      display: block;
      font-size: 22px;
      text-align: right;
    }
    
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }

    .table>:not(caption) {
        border-bottom-width: 1px !important;
    
    }
  }